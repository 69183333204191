import { LoadingOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Dropdown, Menu, Spin, Tooltip } from 'antd';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import { CheckSvg } from '../../../assets/icons';
import MoreSvg from '../../../assets/icons/MoreSvg';
import ProfileChecked from '../../../assets/icons/ProfileChecked';
import emptyProfilePhoto from '../../../assets/images/emptyProfilePhoto.png';
import fallbackCoverPhoto from '../../../assets/images/emptyTemplateCoverPhoto.png';
import theme from '../../../assets/theme';
import useAddToMyLessons from '../../../hooks/useAddToMyLessons';
import useAuthGuardPrompt from '../../../hooks/useAuthGuardPrompt';
import useAutosaveTemplate from '../../../hooks/useAutosaveTemplate';
import usePaywallPrompt from '../../../hooks/usePaywallPrompt';
import useRouterMatch from '../../../hooks/useRouterMatch';
import { useTemplateCreatorPhoto } from '../../../hooks/useTemplateCreatorPhoto';
import { CoverPhotoRenderer } from '../../../pages/discover/index_deprecated';
import {
  DiscoveredTemplate,
  IndexedTemplate,
} from '../../../services/backendService/types';
import metrics from '../../../services/metrics';
import { IconWrapper } from '../../_atoms/IconWrapper';
import { ProfileCircle } from '../../ProfileCircle';
import { Slide } from '../../SlideComponents/slide.types';

const estimatedLessonDuration = (slides: Slide[]) => {
  let durationEstimate = 0;

  slides.forEach(slide => {
    const duration = slide.durationSeconds || 30;
    durationEstimate += duration;
  });
  const durationToMinutes =
    (durationEstimate + 180) / 60 <= 5 ? 5 : ((durationEstimate + 180) / 60).toFixed(0);
  return durationToMinutes;
};

export const LessonCard = ({
  template,
  onClick,
  link,
  authorLink,
  style,
}: {
  template: DiscoveredTemplate | IndexedTemplate;
  onClick?: (templateId: string) => void;
  link: string;
  authorLink: string;
  style?: React.CSSProperties;
}) => {
  const {
    shouldPromptPaywall,
    promptPaywall,
    paywallComponent,
    isReady,
  } = usePaywallPrompt({
    startState: { screen: 'paywall' },
  });
  const session = useSession();
  const { authGuardComponent, showAuthGuard } = useAuthGuardPrompt();
  const { showNewMessages, trackEvent } = useIntercom();
  const { t } = useTranslation('Host');
  const { router, tenantId } = useRouterMatch();
  const client = useQueryClient();
  const [isHovering, setIsHovering] = useState(false);
  const { thumbnail: creatorThumbnail } = useTemplateCreatorPhoto({
    creatorThumbnailId: template.creatorThumbnailId,
    isPrivate: false,
  });

  const { addToMyLessons, isSuccess, isLoading, data } = useAddToMyLessons();
  const {
    autoSaveTemplate,
    isSavingTemplate,
    isSavingTemplateSuccess,
  } = useAutosaveTemplate();

  useEffect(() => {
    if (isSuccess) setIsDropdownVisible(false);
  }, [isSuccess]);

  const menuClick: MenuClickEventHandler = ({ domEvent, key }) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();
    setIsDropdownVisible(true);
    switch (key) {
      case 'addToLessons':
        handleAddToLessons();
        break;

      case 'reportContent':
        handleReportContent();
        break;

      case 'makePrivate':
        handleMakeLessonPrivate();
        break;
      case 'unauthenticated':
        showAuthGuard();
        break;

      default:
        break;
    }
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleVisibleChange = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleGoToMyLesson = () => {
    router.push(`/${tenantId}/lessons/${data?.id}/edit`);
  };

  const handleReportContent = () => {
    showNewMessages(
      `Report content from - ${template.title} \n(Lesson ID: ${template.id}) - \nReason: `,
    );
    trackEvent('Discover.ReportContent', {
      title: template.title,
      templateId: template.id,
      creatorId: template.creatorId,
      downloadCount: template.downloadCount,
      playCount: template.playCount,
      createdAt: new Date(template.createdAt).toDateString(),
    });
  };

  const handleMakeLessonPrivate = () => {
    //Using autosave templateSharingPublic is set to false, both unpublishing it from discover and preventing it from being published again
    autoSaveTemplate({
      templateConfig: {
        id: template.id,
        modifiedAt: new Date().getTime(),
        templateSharingPublic: false,
      },
    });
  };

  const handleAddToLessons = () => {
    if (!isSuccess) {
      if (!isReady) {
        return;
      }
      if (shouldPromptPaywall()) {
        promptPaywall();
        return;
      } else {
        addToMyLessons({
          templateId: template.id,
          activeTenantId: tenantId,
          onSuccessFunction: () => {
            client.invalidateQueries(['creators', template.creatorProfileId]);
          },
        });
      }
    }
  };

  const actionsMenu = (
    <Menu onClick={menuClick}>
      <Menu.Item key={'addToLessons'}>
        {!isLoading && !isSuccess && <> {t('Add to my lessons')}</>}
        {isLoading && !isSuccess && (
          <MenuRow>
            <p>{t('Adding lesson')}</p>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
          </MenuRow>
        )}
        {isSuccess && (
          <MenuRow onClick={handleGoToMyLesson}>
            <p>{t('Go to lesson')}</p>{' '}
            <IconWrapper height="16px" width="16px" color={theme.colors.green}>
              <CheckSvg />
            </IconWrapper>
          </MenuRow>
        )}
      </Menu.Item>
      <Menu.Item key={tenantId ? 'reportContent' : 'unauthenticated'}>
        {!isLoading && !isSuccess && <> {t('Report content')}</>}
        {isLoading && !isSuccess && (
          <MenuRow>
            <p>{t('Reporting content')}</p>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
          </MenuRow>
        )}
        {isSuccess && (
          <MenuRow>
            <p>{t('Content has been reported')}</p>{' '}
            <IconWrapper height="16px" width="16px" color={theme.colors.green}>
              <CheckSvg />
            </IconWrapper>
          </MenuRow>
        )}
      </Menu.Item>
      {template.tenantId === tenantId && template.creatorId === session?.data?.user?.uid && (
        <Menu.Item key={'makePrivate'}>
          {!isSavingTemplate && !isSavingTemplateSuccess && (
            <> {t('Make this lesson private')}</>
          )}
          {isSavingTemplate && !isSavingTemplateSuccess && (
            <MenuRow>
              <p>{t('Removing lesson from discover')}</p>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
            </MenuRow>
          )}
          {isSavingTemplateSuccess && (
            <MenuRow>
              <p>{t('Lesson is now private')}</p>{' '}
              <IconWrapper height="16px" width="16px" color={theme.colors.green}>
                <CheckSvg />
              </IconWrapper>
            </MenuRow>
          )}
        </Menu.Item>
      )}
    </Menu>
  );

  const time = useMemo(() => estimatedLessonDuration(template.slides || []), [template]);

  const animation = useAnimation();

  const hoverSequence = async () => {
    await animation.start({ scale: 1.03, transition: { duration: 0.1 } });
    animation.start({ scale: 1, transition: { duration: 0.1 } });
  };

  return (
    <Link href={link} passHref>
      <a>
        <AnimatePresence>
          <motion.div
            animate={animation}
            onHoverStart={hoverSequence}
            style={{ width: '100%', height: '100%' }}
          >
            {paywallComponent}
            {authGuardComponent}
            <LessonCardContainer
              className="lessonCardContainer"
              onClick={() => onClick && onClick(template.id)}
              style={style ? style : {}}
              onMouseEnter={e => {
                e.preventDefault();
                setIsHovering(true);
              }}
              onMouseLeave={e => {
                e.preventDefault();
                setIsHovering(false);
              }}
            >
              <ImageWrapper style={{ position: 'relative' }}>
                {isHovering && (
                  <div style={{ width: '100%', overflow: 'hidden', padding: '20px' }}>
                    <InfoContainer>
                      <InfoContainerTitle>{template.title}</InfoContainerTitle>
                      <InfoContainerDescription>
                        {template.description}
                      </InfoContainerDescription>
                    </InfoContainer>
                  </div>
                )}
                {template.coverImageId && !isHovering && (
                  <CoverPhotoRenderer
                    id={template.id}
                    coverImageId={template.coverImageId}
                    coverImageAltText={
                      template.coverImageAltText
                        ? template.coverImageAltText
                        : template.title
                    }
                  />
                )}
                {!template.coverImageId && !isHovering && (
                  <PhotoImage src={fallbackCoverPhoto.src} alt="Curipod placeholder" />
                )}
                {!isHovering && (
                  <NumberOfRounds>
                    {time} {t('minutes')}
                  </NumberOfRounds>
                )}
              </ImageWrapper>

              <ProfileWrapper>
                <ProfileSection>
                  <ProfileCircle $radius={40}>
                    <Link href={authorLink} passHref>
                      <a
                        onClick={() => {
                          if (session.status === 'authenticated') {
                            metrics.getLogger().logEvent('TemplateCard.Creator.Clicked', {
                              creatorId: template.creatorProfileId,
                            });
                          }
                        }}
                      >
                        <Image
                          style={{ borderRadius: '50%' }}
                          width={'100%'}
                          height={'100%'}
                          src={creatorThumbnail || emptyProfilePhoto}
                          alt="profile"
                        />
                      </a>
                    </Link>
                  </ProfileCircle>
                  <ProfileInfoContainer>
                    <Link href={link} passHref>
                      <a>
                        <h4>{template.title}</h4>
                      </a>
                    </Link>
                    <AuthorSection>
                      <Tooltip title={t('Verified')}>
                        <IconWrapper height="16px" width="16px" style={{ flex: 0 }}>
                          <ProfileChecked />
                        </IconWrapper>
                      </Tooltip>
                      <div style={{ flex: '1 1 auto' }}>
                        <Link href={authorLink} passHref>
                          <a
                            onClick={() => {
                              if (session.status === 'authenticated') {
                                metrics
                                  .getLogger()
                                  .logEvent('TemplateCard.Creator.Clicked', {
                                    creatorId: template.creatorProfileId,
                                  });
                              }
                            }}
                          >
                            <p>{template.creatorName}</p>
                          </a>
                        </Link>
                      </div>
                    </AuthorSection>
                  </ProfileInfoContainer>
                  <div
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Dropdown
                      trigger={['click']}
                      overlay={actionsMenu}
                      onVisibleChange={handleVisibleChange}
                      visible={isDropdownVisible}
                      placement="bottomRight"
                    >
                      <IconWrapper
                        style={{ flex: '0 1 24px' }}
                        color="#A5A5A5"
                        height={'24px'}
                        width={'24px'}
                      >
                        <MoreSvg />
                      </IconWrapper>
                    </Dropdown>
                  </div>
                </ProfileSection>
              </ProfileWrapper>
            </LessonCardContainer>
          </motion.div>
        </AnimatePresence>
      </a>
    </Link>
  );
};

const MenuRow = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`;

const PhotoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const AuthorSection = styled.div`
  display: flex;
  align-items: center;
  transition: 0.5s;
  overflow-x: hidden;
  width: 100%;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      text-decoration: underline;
    }
  }
  svg {
    color: #ffa55b;
  }
`;

const InfoContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
`;
const InfoContainerTitle = styled.h1`
  font-weight: bold;
  font-style: italic;
  font-size: 18px;
  width: 100%;
`;
const InfoContainerDescription = styled.p`
  width: 100%;
  font-style: italic;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 70%;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 400px) {
    height: 60%;
  }
`;

const NumberOfRounds = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: ${theme.colors.translucentBlack};
  border-radius: 5px;
  color: ${theme.colors.white};
  padding: 2px 5px;
  font-size: 14px;
`;
const ProfileWrapper = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 400px) {
    height: 40%;
  }
`;

const ProfileSection = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;

  justify-content: space-between;

  h2 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 0px);
  }

  h3 {
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;
    color: ${theme.colors.text.dark};
  }

  span {
    display: flex;
    margin: 0px 5px 4px 0px;
  }
`;

const ProfileInfoContainer = styled.div`
  padding-left: 10px;
  flex: 1;
  min-width: 0;

  h4 {
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    margin-bottom: 0px;
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const LessonCardContainer = styled.div`
  cursor: pointer;
  background: ${theme.colors.white};
  height: 220px;
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);

  h2 {
    font-weight: bold;
  }

  @media (max-width: 400px) {
    border-radius: 5px;
  }
`;

export default LessonCard;
